html {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: #fbdd74;
  /*background: #96fb74;*/

  color: #294910;
  height: 100vh;
  width: 100vw;
  margin: 0;
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

section {
  display: flex;
  height: 100vh;
  justify-content: space-between;
}



h1 {
  font-weight: 600;
  font-size: 10vw;
  margin: 0 20px;
  line-height: 1.2;
}

h2 {
  bottom: 0;
  position: fixed;
  font-size: 24px;
  line-height: 1.4;
  margin: 0 20px;
  font-weight: normal;
}

figure {
  background: #ffffff;
  margin: 0;
  position: relative;
  width: 50vw;
}

figcaption {
  bottom: 0;
  color: #ffffff;
  font-size: 40px;
  font-weight: normal;
  position: absolute;
  text-align: center;
  z-index: 1;
  margin: 0 auto; /*this will only work if element have a specified width */
  width: 100%;
  padding : 20px 0;

}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;

}


aside{
  height:100%;
  width: 100%;
  position: absolute;
  background: #e8e8e8;
  display: flex;
  z-index: 2;
  align-items: center;  /* Vertically / Perpendicular to main axis */
  justify-content: center; /* Alignment along the main axis ie Horizontal */
}

.loading-bar{
  display: grid;
  grid-row-gap: 12px;
}
progress{
  border: 1px solid #294910;
  height:20px;
  width: 400px;
}

progress[value]::-webkit-progress-bar{
  background: #e8e8e8;
}

progress[value]::-webkit-progress-value{
  background: #294910;
}

.display{
  /*transition: opacity 0.1s ease-in-out;*/
  opacity: 1;
}

.hide{
  opacity: 0;
}


@media(max-width: 800px){
/* It will activate when the browser width is under 800px */



  h1{
    font-size: 80px;
    font-weight: 600;
  }
  h2{
    font-size: 20px;
    position: relative;
  }

  section{
    flex-direction: column;
  }

  figure{
    flex-grow: 1;
    width:100vw;
  }


  figcaption{
    width: 100vw;
    font-size: 30px;
    text-align: center;
  }

}

@media(max-width: 400px){

  progress{
    border: 1px solid #294910;
    height:20px;
    width: 350px;

  }
  figcaption{
    padding : 60px 0;

  }

}
